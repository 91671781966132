import { isObject } from "lodash-es";
import { useToast } from "vue-toastification";

export function formatErrorMessage({ error, message }: { error: any, message?: string}) {
  if (!message) {
    if (error.reason) {
      message = error.reason as string;
    } else if (error?.options?.method === 'GET') {
      message = 'Failed to load data';
    } else if (['POST', 'PUT', 'PATCH'].includes(error?.options?.method)) {
      message = 'Failed to save data';
    } else if (error?.options?.method === 'DELETE') {
      message = 'Failed to delete data';
    } else {
      message = 'Request error';
    }
  }
  if (error?.data?.detail) {
    message += ': ' + error?.data?.detail;
  } else if (Array.isArray(error?.data)) {
    message += ': ' + error.data.join(', ')
  } else if (error?.data?.non_field_errors) {
    message += ': ' + error.data.non_field_errors.join(', ');
  } else if (error?.status === 429) {
    message += ': Exceeded rate limit. Try again later.';
  } else if (isObject(error?.data)) {
    const entries = Object.values(error.data)
      .filter(v => Array.isArray(v))
      .flat();
    message += ': ' + entries.join(', ');
  }

  return message;
}

export function requestErrorToast({ error, message }: { error: any, message?: string}) {
  // eslint-disable-next-line no-console
  console.log('Request error', { error, message }, error?.data);

  if (error?.options?.signal?.aborted) {
    return;  // Do not show toast for aborted requests
  }
  errorToast(formatErrorMessage({ error, message }));
}

export function successToast(message: string) {
  const toast = useToast();
  toast.success(message);
}

export function errorToast(message: string) {
  const toast = useToast();
  toast.error(message);
}

export function warningToast(message: string) {
  const toast = useToast();
  toast.warning(message);
}

export async function requestErrorToastWrapper(fn: () => any) {
  try {
    return await Promise.resolve(fn());
  } catch (error) {
    requestErrorToast({ error });
  }
}
